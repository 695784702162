/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	animated, config, useSpring,
} from 'react-spring';

export default React.memo(({
	img, text, language, name,
}) => {
	const [hover, setHover] = React.useState(false);
	const history = useHistory();

	const spring = useSpring({
		to: { opacity: hover ? 0.8 : 0.8 },
		config: config.stiff,
	});

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				position: 'relative',
				backgroundImage: `url(${img})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				cursor: 'pointer',
			}}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
			onClick={() => {
				history.push(`/${language}/${name}`);
			}}
		>
			<animated.div
				style={{
					position: 'absolute',
					transform: 'translate(0%, -50%)',
					top: '50%',
					textAlign: 'center',
					width: '100%',
					height: '100%',
					backgroundColor: 'rgb(0,0,0,0.7)',
					zIndex: 10000000,
					opacity: spring.opacity,
				}}
			/>
			<img
				src={img}
				style={{ opacity: 0 }}
				width="100%"
				alt=""
				srcSet=""
			/>
			<animated.div style={{
				position: 'absolute',
				transform: 'translate(0%, -50%)',
				top: '50%',
				textAlign: 'center',
				width: '100%',
				zIndex: 10000000000,
				fontFamily: 'AvenirRegular',
				opacity: spring.opacity,
			}}
			>
				{text}
			</animated.div>
		</div>
	);
});
