/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import SimpleReactLightbox, {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';
import { useHistory } from 'react-router';

import cava1 from '../../assets/img/cava/Cava1.jpg';
import cava2 from '../../assets/img/cava/Cava2.jpg';
import cava3 from '../../assets/img/cava/Cava3.jpg';
import cava4 from '../../assets/img/cava/Cava4.jpg';
import backarrow from '../../assets/img/backarrow.png';

const images = [
	{
		src: cava1,
	},
	{
		src: cava2,
	},
	{
		src: cava3,
	},
	{
		src: cava4,
	},
];

export default React.memo(({ language }) => {
	const a = 1;
	const { openLightbox } = useLightbox();
	const history = useHistory();

	let fotosText = 'Photography';
	let text = 'Located among the forests of Jalisco Mexico, "La Cava" represents the intervention of a cave in a country house to create the wine room.';
	let textHeader = '"As if they had always been there" ';
	if (language === 'es') {
		fotosText = 'Fotografía';
		text = 'Situada entre los bosques de Jalisco México, "La Cava" representa la intervención del subsuelo en una casa de campo para crear el cuarto del vino.';
		textHeader = '“ Como si siempre hubieran estado ahí “ ';
	} else if (language === 'it') {
		text = 'Situata tra i boschi di Jalisco Mexico, "La Cava" rappresenta l\'intervento del sottosuolo in una casa di campagna per creare la stanza del vino.';
		textHeader = '" Come se ci fossero sempre stati " ';
		fotosText = 'Fotografia';
	}

	return (
		<>
			<>
				<Grid
					style={{
						color: 'white',
						fontSize: 20,
						// maxHeight: 500,
						width: '100%',
						overflow: 'auto',
						transform: 'translate(50%, -50%)',
						top: '50%',
						right: '50%',
						padding: 20,
						position: 'absolute',
						// backgroundColor: 'blue',
					}}
				>
					<SRLWrapper
						images={[images]}
						options={{
							buttons: {
								showAutoplayButton: false,
								showCloseButton: true,
								showDownloadButton: false,
								showFullscreenButton: false,
								showNextButton: true,
								showPrevButton: true,
								showThumbnailsButton: false,
							},
							settings: {

								overlayColor: 'rgba(0, 0, 0,0.9)',
							},
						}}
					>
						{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
					</SRLWrapper>
					<Row>
						<Col
							sm={6}
							lg={6}
							md={6}
							lgOffset={3}
							smOffset={3}
							mdOffset={3}
						>
							<Row style={{ backgroundColor: '', minHeight: 350 }}>
								<div
									style={{
										width: '55%',

										backgroundImage: `url(${cava1})`,
										backgroundPosition: 'center center',
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
										float: 'left',
										cursor: 'pointer',

									}}
									onClick={() => {
										openLightbox(0);
									}}
								/>
								<div style={{ height: '100%', width: '4%' }} />
								<div style={{
									width: '41%',
									float: 'right',
									position: 'relative',
								}}
								>
									<p style={{ fontSize: 16, textAlign: 'justify' }}>
										<br />
										<br />
										{textHeader}
										<br />
										<br />
										{text}
									</p>
								</div>
							</Row>
							<div style={{
				 position: 'relative', minHeight: 40, marginTop: 100,
							}}
							>
								<img
									onClick={() => {
										history.goBack();
									}}
									src={backarrow}
									style={{
										height: 35,
										position: 'absolute',
										top: 0,
										left: '50%',
										cursor: 'pointer',

									}}
								/>
							</div>

						</Col>
					</Row>
				</Grid>
			</>
		</>
	);
});
