import { makeStyles } from '@material-ui/styles';
import React from 'react';
import {
	useSpring, animated, config,
} from 'react-spring';

const vhToPixel = (value) => `${(window.innerHeight * value) / 100}px`;
const vwToPixel = (value) => `${(window.innerWidth * value) / 100}px`;

const useStyles = makeStyles({
	root: {
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 0,
	},
	leftPanel: {
		backgroundColor: 'rgba(0,0,0,0.45)',
		position: 'absolute',
		// transform: 'translate(0, -50%)',
		// top: '50%',
		right: 0,
	},
});

export default React.memo(({
	isPageOpen, isMenuOpen, isLanguageOpen,
}) => {
	const classes = useStyles();
	const [dimensions, setDimensions] = React.useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	const width = '100%';
	let height = '90px';
	let top = '0%';
	let transform = 'translate(0, 0%)';

	if (isLanguageOpen) {
		top = '50%';
		transform = 'translate(0, -50%)';
	}

	if (isPageOpen) {
		height = vhToPixel(100);
	} else if (isMenuOpen) {
		height = '230px';
	}

	const spring = useSpring({
		to: {
			width, height, top, transform,
		},
		config: config.slow,
	});

	React.useEffect(() => {
		function handleResize() {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<div className={classes.root}>
			<animated.div
				style={spring}
				className={classes.leftPanel}
			/>
		</div>
	);
});
