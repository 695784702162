/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import SimpleReactLightbox, {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';
import { useHistory } from 'react-router';
import Proyect from './Proyect';
import pdf from '../../assets/pdf/clip.pdf';
import newDownload from '../../assets/img/whiteDownload.jpg'


import img from '../../assets/img/finallow/clip-thumb.jpeg';
import img16 from '../../assets/img/finallow/img16-min.jpeg';
import img18 from '../../assets/img/finallow/img18-min.jpg';
import img19 from '../../assets/img/finallow/img19-min.jpg';
import img20 from '../../assets/img/finallow/img20-min.jpeg';
import backarrow from '../../assets/img/backarrow.png';

const images = [
	{
		src: img16,
	},
	{
		src: img18,
	},
	{
		src: img19,
	},
	{
		src: img20,
	},
];

export default React.memo(({ language }) => {
	const a = 1;
	const { openLightbox } = useLightbox();
	const history = useHistory();

	let fotosText = 'Photography';
	let text = 'Made with steel and leather by hand from Mexican artisans, the Clip chair was born from the experimentation of forms that are achieved from the unfolding of a single stroke; As well as unfolding a clip...';
	if (language === 'es') {
		fotosText = 'Fotografía';
	 	text = 'Elaborada con acero y piel a mano de artesanos Mexicanos, la silla Clip nace de la  experimentación de formas que se logran a partir del desdoblamiento de un solo trazo; Así como desdoblando un clip…';
	} else if (language === 'it') {
		text = 'Realizzata in acciaio e pelle a mano da artigiani Messicani, la sedia Clip nasce dalla sperimentazione di forme che si realizzano a partire dallo sdoppiamento di un solo tratto; Come dividere una graffetta...';
		fotosText = 'Fotografia';
	}

	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					// maxHeight: 500,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
					// backgroundColor: 'blue',
				}}
			>
				<SRLWrapper
					images={[images]}
					options={{
						buttons: {
							showAutoplayButton: false,
							showCloseButton: true,
							showDownloadButton: false,
							showFullscreenButton: false,
							showNextButton: true,
							showPrevButton: true,
							showThumbnailsButton: false,
						},
						settings: {

							overlayColor: 'rgba(0, 0, 0,0.9)',
						},
					}}
				>
					{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
				</SRLWrapper>
				<Row>
					<Col
						sm={6}
						lg={6}
						md={6}
						lgOffset={3}
						smOffset={3}
						mdOffset={3}
					>
						<Row style={{ backgroundColor: '', minHeight: 350 }}>
							<div
								style={{
									width: '55%',

									backgroundImage: `url(${img})`,
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									float: 'left',
									cursor: 'pointer',

								}}
								onClick={() => {
									openLightbox(0);
								}}
							>

								{/* <img src={img5} width="100%" alt="" srcSet="" /> */}
							</div>
							<div style={{ height: '100%', width: '4%' }} />
							<div style={{
								width: '41%',
								// backgroundColor: 'green',
								// padding: 20,
								float: 'right',
								position: 'relative',
							}}
							>
								<p style={{ fontSize: 16, textAlign: 'justify' }}>
									<br />
									<br />
									{text}
									{/* Elaborada con madera de nogal y piel de vacuno de la mano de artesanos Mexicanos, la silla Tulum refleja a través de su forma, su origen. Un diseño concebido mediante el trazo a mano alzada, que invita a dos líneas a fluir hasta converger en su trayecto y formar el boceto de una sola pieza. */}
								</p>

								<div style={{

									position: 'absolute',
									bottom: 0,
									right: 0,
									width: '25%',
									right: '40%'
								}}
								>
									<button
										style={{
											width: '100%',
											border: 'none',
											backgroundColor: 'rgba(255,255,255,0.7)',
											cursor: 'pointer',
											background: 'none'
										}}
										onClick={() => {
											window.open(pdf);
										}}
									>
										<img
											src={newDownload}
											alt=""
											srcSet=""
											width="25px"
											style={{ margin: 5 }}
										/>
									</button>
								</div>
							</div>
						</Row>
						<div style={{
							 position: 'relative', minHeight: 40, marginTop: 100,
						}}
						>
							<img
								onClick={() => {
									history.goBack();
								}}
								src={backarrow}
								style={{
									height: 35,
									position: 'absolute',
									top: 0,
									left: '50%',
									cursor: 'pointer',

								}}
							/>
						</div>

					</Col>
				</Row>
			</Grid>
		</>
	);
});
