import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { animated, config, useSpring } from 'react-spring';

import instagram from '../../assets/img/instagram2.png';

const useStyles = makeStyles({
	root: {
		position: 'absolute',
		transform: 'translate(0, -50%)',
		top: '45px',
		zIndex: 100,
	},
});

export default React.memo(({ isMenuOpen, isPageOpen, isLanguageOpen }) => {
	const classes = useStyles();

	let opacity = 1;
	if (isLanguageOpen) {
		opacity = 0;
	}

	let right = 40;
	if (isPageOpen) {
		right = 30;
	}

	let height = 28;
	if (isPageOpen) {
		height = 20;
	}

	const spring = useSpring({
		to: { opacity, right, height },
		config: config.slow,
	});

	return (
		<animated.div
			style={{
				...spring,
				display: isLanguageOpen ? 'none' : 'block',
			}}
			className={classes.root}
		>
			<a href="https://www.instagram.com/ardesignmx/" target="_blank" rel="noopener noreferrer">
				<animated.img
					src={instagram}
					alt=""
					srcSet=""
					height={spring.height}
				/>
			</a>
		</animated.div>
	);
});
