/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import SimpleReactLightbox, {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';

import sala1 from '../../assets/img/sala-piedra/Sala1.jpg';
import sala2 from '../../assets/img/sala-piedra/Sala2.jpg';
import sala3 from '../../assets/img/sala-piedra/Sala3.jpg';
import sala4 from '../../assets/img/sala-piedra/Sala4.jpg';

const images = [
	{
		src: sala1,
	},
	{
		src: sala2,
	},
	{
		src: sala3,
	},
	{
		src: sala4,
	},
];
export default React.memo(({ language }) => {
	const a = 1;
	const { openLightbox } = useLightbox();

	let fotosText = 'Photography';
	if (language === 'es') {
		fotosText = 'Fotografía';
	} else if (language === 'it') {
		fotosText = 'Fotografia';
	}

	let text = 'Four stone monoliths are installed to generate the furniture of a living room.';
	if (language === 'es') {
		fotosText = 'Fotografía';
		text = 'Cuatro monolitos de piedra se instalan para generar el mobiliario de una sala.';
	} else if (language === 'it') {
		text = 'Quattro monoliti in pietra sono installati per generare i mobili di una stanza.';
		fotosText = 'Fotografia';
	}

	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					// maxHeight: 500,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
					// backgroundColor: 'blue',
				}}
			>
				<SRLWrapper
					images={[images]}
					options={{
						buttons: {
							showAutoplayButton: false,
							showCloseButton: true,
							showDownloadButton: false,
							showFullscreenButton: false,
							showNextButton: true,
							showPrevButton: true,
							showThumbnailsButton: false,
						},
						settings: {

							overlayColor: 'rgba(0, 0, 0,0.8)',
						},
					}}
				>
					{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
				</SRLWrapper>
				<Row>
					<Col
						xs={12}
						// lg={6}
						// md={6}
						// lgOffset={3}
						// smOffset={3}
						// mdOffset={3}
					>
						<Row style={{ backgroundColor: '' }}>
							<div
								style={{
									width: '100%',
									backgroundImage: `url(${sala1})`,
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									// float: 'left',
									height: '200px',

								}}
								onClick={() => {
									openLightbox(0);
								}}
							>

								{/* <img src={img5} width="100%" alt="" srcSet="" /> */}
							</div>
						</Row>
						<Row>
							{/* <div style={{ height: '100%', width: '4%' }} /> */}
							<div style={{
								width: '100%',
								// backgroundColor: 'red',
								// backgroundColor: 'green',
								// padding: 20,
								// float: 'right',
								// height: '300px',

								position: 'relative',
							}}
							>
								<p style={{ fontSize: 16, textAlign: 'justify' }}>
									{text}
									{/* Elaborada con madera de nogal y piel de vacuno de la mano de artesanos Mexicanos, la silla Tulum refleja a través de su forma, su origen. Un diseño concebido mediante el trazo a mano alzada, que invita a dos líneas a fluir hasta converger en su trayecto y formar el boceto de una sola pieza. */}
								</p>
							</div>
						</Row>
					</Col>
				</Row>
			</Grid>
		</>
	);
});
