import React from 'react';
import { useHistory } from 'react-router-dom';
import Gallery from '../Gallery';

import hubImage1 from '../../assets/img/hub/hub-1.jpg';
import hubImage2 from '../../assets/img/hub/hub-2.jpeg';
import hubImage3 from '../../assets/img/hub/hub-3.jpeg';
import hubImage4 from '../../assets/img/hub/hub-4.jpg';
import hubImage5 from '../../assets/img/hub/hub-5.jpg';
import hubImage6 from '../../assets/img/hub/hub-6.jpg';
import hubImage7 from '../../assets/img/hub/hub-7.jpg';
import hubImage8 from '../../assets/img/hub/hub-8.jpg';
import hubImage9 from '../../assets/img/hub/hub-9.jpg';
import hubImage10 from '../../assets/img/hub/hub-10.jpeg';
import hubImage11 from '../../assets/img/hub/hub-11.jpg';
import hubImage12 from '../../assets/img/hub/hub-12.jpeg';
import hubImage13 from '../../assets/img/hub/hub-13.jpeg';
import hubImage14 from '../../assets/img/hub/hub-14.jpg';

import tulum1 from '../../assets/img/tulum/Tulum1.jpg';
import tulum2 from '../../assets/img/tulum/Tulum2.jpg';
import tulum3 from '../../assets/img/tulum/Tulum3.jpg';
import tulum4 from '../../assets/img/tulum/Tulum4.jpg';

import sala1 from '../../assets/img/sala-piedra/Sala1.jpg';
import sala2 from '../../assets/img/sala-piedra/Sala2.jpg';
import sala3 from '../../assets/img/sala-piedra/Sala3.jpg';
import sala4 from '../../assets/img/sala-piedra/Sala4.jpg';

import oliva1 from '../../assets/img/oliva/Oliva1.jpg';
import oliva2 from '../../assets/img/oliva/Oliva2.jpg';
import oliva3 from '../../assets/img/oliva/Oliva3.jpg';
import oliva4 from '../../assets/img/oliva/Oliva4.jpg';

import grecia1 from '../../assets/img/grecia/Grecia1.jpg';
import grecia2 from '../../assets/img/grecia/Grecia2.jpg';
import grecia3 from '../../assets/img/grecia/Grecia3.jpg';

import clip1 from '../../assets/img/clip/Clip1.jpg';
import clip2 from '../../assets/img/clip/Clip2.jpg';
import clip3 from '../../assets/img/clip/Clip3.jpg';
import clip4 from '../../assets/img/clip/Clip4.jpg';

import cava1 from '../../assets/img/cava/Cava1.jpg';
import cava2 from '../../assets/img/cava/Cava2.jpg';
import cava3 from '../../assets/img/cava/Cava3.jpg';
import cava4 from '../../assets/img/cava/Cava4.jpg';

import BackdropDesktop from './BackdropDesktop';
import InstagramLogo from './InstagramLogo';
import AnimatedLogo from './AnimatedLogo';
import FooterDesktop from './FooterDesktop';
import LanguageSeleccion from './LanguageSelection';
import InnerPage from './InnerPage';

const imgs = [
	{ src: hubImage1, id: 0, icon: 0 },
	{ src: hubImage2, id: 1, icon: 0 },
	{ src: hubImage3, id: 2, icon: 0 },
	{ src: hubImage4, id: 3, icon: 0 },
	{ src: hubImage5, id: 4, icon: 0 },
	{ src: hubImage6, id: 5, icon: 0 },
	{ src: hubImage7, id: 6, icon: 0 },
	{ src: hubImage8, id: 7, icon: 0 },
	{ src: hubImage9, id: 8, icon: 0 },
	{ src: hubImage10, id: 9, icon: 0 },
	{ src: hubImage11, id: 10, icon: 0 },
	{ src: hubImage12, id: 11, icon: 0 },
	{ src: hubImage13, id: 12, icon: 0 },
	{ src: hubImage14, id: 13, icon: 0 },
	{ src: tulum1, id: 20, icon: 5 },
	{ src: tulum2, id: 21, icon: 5 },
	{ src: tulum3, id: 22, icon: 5 },
	{ src: tulum4, id: 23, icon: 5 },
	{ src: sala1, id: 24, icon: 9 },
	{ src: sala2, id: 25, icon: 9 },
	{ src: sala3, id: 26, icon: 9 },
	{ src: sala4, id: 27, icon: 9 },
	{ src: oliva1, id: 28, icon: 4 },
	{ src: oliva2, id: 29, icon: 4 },
	{ src: oliva3, id: 30, icon: 4 },
	{ src: oliva4, id: 31, icon: 4 },
	{ src: grecia1, id: 32, icon: 6 },
	{ src: grecia2, id: 33, icon: 6 },
	{ src: grecia3, id: 34, icon: 6 },
	{ src: clip1, id: 36, icon: 7 },
	{ src: clip2, id: 37, icon: 7 },
	{ src: clip3, id: 38, icon: 7 },
	{ src: clip4, id: 39, icon: 7 },
	{ src: cava1, id: 40, icon: 8 },
	{ src: cava2, id: 41, icon: 8 },
	{ src: cava3, id: 42, icon: 8 },
	{ src: cava4, id: 43, icon: 8 },
];

document.lastY = 0;
export default ({
	isPageOpen, isLanguageOpen, language, openedPage,
}) => {
	const history = useHistory();

	const [icon, setIcon] = React.useState(1);

	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	const openMenu = React.useCallback(() => {
		setIsMenuOpen((o) => !o);
	}, []);

	const openHome = React.useCallback(() => {
		setIsMenuOpen(false);
		switch (language) {
		case 'en':
			history.push('/en');
			break;
		case 'es':
			history.push('/es');
			break;
		case 'it':
			history.push('/it');
			break;
		default:
			break;
		}
	}, [history, language]);

	const openAboutus = React.useCallback(() => {
		setIsMenuOpen(false);
		switch (language) {
		case 'en':
			history.push('/en/about');
			break;
		case 'es':
			history.push('/es/nosotros');
			break;
		case 'it':
			history.push('/it/noi');
			break;
		default:
			break;
		}
	}, [history, language]);

	const openProjects = React.useCallback(() => {
		setIsMenuOpen(false);
		switch (language) {
		case 'en':
			history.push('/en/projects');
			break;
		case 'es':
			history.push('/es/proyectos');
			break;
		case 'it':
			history.push('/it/progetti');
			break;
		default:
			break;
		}
	}, [history, language]);

	const openContact = React.useCallback(() => {
		setIsMenuOpen(false);
		switch (language) {
		case 'en':
			history.push('/en/contact');
			break;
		case 'es':
			history.push('/es/contacto');
			break;
		case 'it':
			history.push('/it/contatto');
			break;
		default:
			break;
		}
	}, [history, language]);

	const openShop = React.useCallback(() => {
		setIsMenuOpen(false);
		window.open('https://shop.ardesign.mx/', '_blank');
	}, []);

	const changeIcon = React.useCallback((img) => {
		if (img) {
			setIcon(img.icon);
		}
	}, []);

	React.useEffect(() => {
		setIsMenuOpen(false);
	}, [isPageOpen, isLanguageOpen]);

	let imagess = imgs.filter((e) => e.icon === 0);
	if (openedPage === 7) {
		imagess = imgs.filter((e) => e.icon === 7);
	} else if (openedPage === 4) {
		imagess = imgs.filter((e) => e.icon === 4);
	} else if (openedPage === 5) {
		imagess = imgs.filter((e) => e.icon === 5);
	} else if (openedPage === 6) {
		imagess = imgs.filter((e) => e.icon === 6);
	} else if (openedPage === 8) {
		imagess = imgs.filter((e) => e.icon === 8);
	} else if (openedPage === 9) {
		imagess = imgs.filter((e) => e.icon === 9);
	}

	return (

		<div style={{ overflow: 'hidden' }}>
			<Gallery
				imgs={imagess}
				onChangeImage={changeIcon}
			/>
			<InstagramLogo
				isMenuOpen={isMenuOpen}
				isPageOpen={isPageOpen}
				isLanguageOpen={isLanguageOpen}
			/>
			<BackdropDesktop
				isMenuOpen={isMenuOpen}
				isPageOpen={isPageOpen}
				isLanguageOpen={isLanguageOpen}
			/>
			<AnimatedLogo
				isMenuOpen={isMenuOpen}
				isPageOpen={isPageOpen}
				isLanguageOpen={isLanguageOpen}
				onMenuOpen={openMenu}
				onHomeClick={openHome}
				onAboutClick={openAboutus}
				onProjectClick={openProjects}
				onContactClick={openContact}
				onShopClick={openShop}
				language={language}
			/>
			<FooterDesktop
				isMenuOpen={isMenuOpen}
				isPageOpen={isPageOpen}
				isLanguageOpen={isLanguageOpen}
				icon={icon}
				language={language}
			/>
			<LanguageSeleccion
				isMenuOpen={isMenuOpen}
				isPageOpen={isPageOpen}
				isLanguageOpen={isLanguageOpen}
			/>
			<InnerPage
				isMenuOpen={isMenuOpen}
				isPageOpen={isPageOpen}
				isLanguageOpen={isLanguageOpen}
				openedPage={openedPage}
				language={language}
			/>

		</div>
	);
};
