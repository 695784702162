/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import SimpleReactLightbox, {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';
import { useHistory } from 'react-router';
import Proyect from './Proyect';
import img6 from '../../assets/img/finallow/img1-min-min-t.jpg';
import pdf from '../../assets/pdf/tulum.pdf';
import newDownload from '../../assets/img/whiteDownload.jpg'


import img1 from '../../assets/img/finallow/img9-min-min.png';
import img3 from '../../assets/img/finallow/img10-min-min.png';
import img5 from '../../assets/img/finallow/img1-min-min.jpg';
import img9 from '../../assets/img/finallow/img2-min-min.jpeg';
import img14 from '../../assets/img/finallow/img6-min-min.png';
import backarrow from '../../assets/img/backarrow.png';

const images = [
	{
		src: img1,
	},
	{
		src: img3,
	},
	{
		src: img5,
	},
	{
		src: img9,
	},
	{
		src: img14,
	},
];

export default React.memo(({ language }) => {
	const a = 1;
	const { openLightbox } = useLightbox();
	const history = useHistory();

	let fotosText = 'Photography';
	let text = 'Elaborada con madera de nogal y piel de vacuno de la mano de artesanos Mexicanos, la silla Tulum refleja a través de su forma, su origen. Un diseño concebido mediante el trazo a mano alzada, que invita a dos líneas a fluir hasta converger en su trayecto y formar el boceto de una sola pieza.';
	if (language === 'es') {
		fotosText = 'Fotografía';
	} else if (language === 'it') {
		text = 'Realizzata in legno di noce e pelle di bovino dalla mano di artigiani messicani, la sedia Tulum riflette attraverso la sua forma, la sua origine. Un design concepito mediante il tratto a mano libera, che invita due linee a fluire fino a convergere nel loro percorso e a formare lo schizzo di un solo pezzo…';
		fotosText = 'Fotografia';
	}

	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					// maxHeight: 500,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
					// backgroundColor: 'blue',
				}}
			>
				<SRLWrapper
					images={[images]}
					options={{
						buttons: {
							showAutoplayButton: false,
							showCloseButton: true,
							showDownloadButton: false,
							showFullscreenButton: false,
							showNextButton: true,
							showPrevButton: true,
							showThumbnailsButton: false,
						},
						settings: {
							overlayColor: 'rgba(0, 0, 0,0.9)',
						},
					}}
				>
					{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
				</SRLWrapper>
				<Row>
					<Col
						sm={6}
						lg={6}
						md={6}
						lgOffset={3}
						smOffset={3}
						mdOffset={3}
					>
						<Row style={{ backgroundColor: '', minHeight: 350 }}>
							<div
								style={{
									width: '55%',

									backgroundImage: `url(${img6})`,
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									float: 'left',
									cursor: 'pointer',

								}}
								onClick={() => {
									openLightbox(0);
								}}
							>

								{/* <img src={img5} width="100%" alt="" srcSet="" /> */}
							</div>
							<div style={{ height: '100%', width: '4%' }} />
							<div style={{
								width: '41%',
								// backgroundColor: 'green',
								// padding: 20,
								float: 'right',
								position: 'relative',
							}}
							>
								<p style={{ fontSize: 16, textAlign: 'justify' }}>
									<br />
									<br />
									{text}
									{/* Elaborada con madera de nogal y piel de vacuno de la mano de artesanos Mexicanos, la silla Tulum refleja a través de su forma, su origen. Un diseño concebido mediante el trazo a mano alzada, que invita a dos líneas a fluir hasta converger en su trayecto y formar el boceto de una sola pieza. */}
								</p>

								<div style={{

									position: 'absolute',
									bottom: 0,
									right: 0,
									width: '25%',
									alignContent:'start',
									left:'40%'
								}}
								>
									<button
										style={{
											width: '100%',
											border: 'none',
											backgroundColor: 'rgba(255,255,255,0.7)',
											cursor: 'pointer',
											background: 'none'
										}}
										onClick={() => {
											window.open(pdf);
										}}
									>
										<img
											src={newDownload}
											alt=""
											srcSet=""
											width="25px"
											style={{ margin: 5 }}
										/>
									</button>
								</div>
							</div>
							{/* <Col xs={7}>

								<img src={img5} width="100%" alt="" srcSet="" />
							</Col> */}
							{/* <Col xs={5}>
								<p style={{ fontSize: 15, textAlign: 'justify' }}>
									Hay objetos que nacen para heredarse…
									<br />
									<br />

									Somos conscientes de que el apego hacia ellos existe, nos rodean y sin darnos cuenta con el paso del tiempo una parte nuestra inevitablemente se adhiere a ellos.

									<br />
									<br />
									De ahí surge el compromiso de crear objetos con personalidad propia que logren comunicar su esencia a través de su forma y materialidad, la cual busca
								</p>
								<div style={{

								}}
								>

									<button style={{
										width: '70%',
										border: 'none',
										backgroundColor: 'rgba(255,255,255,0.8)',
									}}
									>
										<img
											src="https://static.thenounproject.com/png/29074-200.png"
											alt=""
											srcSet=""
											width="25px"
											style={{ margin: 5 }}
										/>
									</button>
									<button style={{
										width: '30%',
										border: 'none',
									}}
									>
										<img
											src="https://banner2.cleanpng.com/20171217/991/shopping-cart-png-5a364b44f3f540.1430760515135076529993.jpg"
											height="25px"
											alt=""
											srcSet=""
											style={{ margin: 5 }}
										/>
									</button>
								</div>

							</Col> */}

						</Row>
						<div style={{
							 position: 'relative', minHeight: 40, marginTop: 100,
						}}
						>
							<img
								onClick={() => {
									history.goBack();
								}}
								src={backarrow}
								style={{
									height: 35,
									position: 'absolute',
									top: 0,
									left: '50%',
									cursor: 'pointer',

								}}
							/>
						</div>

					</Col>
				</Row>
			</Grid>
		</>
	);
});
