import React from 'react';
import {
	useSpring, config, animated, useChain, useTransition,
} from 'react-spring';
import { Grid, Row, Col } from 'react-flexbox-grid';
import nosotros from '../../assets/img/aboutUs/aboutUs.png';

export default React.memo(({ language }) => {
	const [step, setStep] = React.useState(0);

	let sloganText = '“Increasing space quality  through object design”';
	let text = ['Some objects are born to inherit …',
		'We  are  aware of the attachment we have to them, they surround us and soon with the passage of time without even noticing some part of us becomes part of the object.',
		'Our responsability as creators emerges in making pieces that poseed their own personality, communicating their essence through the shapes and materials that defy usual austerity by starting design with raised hand and letting artisans do the final details with their own hands to finally give the object the privilege of transcendence and inherity.',
		'“Design is the art and beauty that forges each piece’s character turning them in to that object that must remain in future generations”',
	];

	if (language === 'es') {
		sloganText = '“Elevando la calidad del espacio a través del diseño de objetos”';
		text = ['Hay objetos que nacen para heredarse…',
			'Somos conscientes de que el apego hacia ellos existe, nos rodean y sin darnos cuenta con el paso del tiempo una parte nuestra inevitablemente se adhiere a ellos.',
			'De ahí surge el compromiso de crear objetos con personalidad propia que logren comunicar su esencia a través de su forma y materialidad, la cual busca romper con la rigidez cotidiana a partir del diseño a mano alzada, dejando que sean más tarde manos de artesanos las que pongan el toque final para otorgarle a cada una de nuestras piezas el privilegio de trascender y convertirse en heredables.',
			'“El diseño es el arte y la belleza con la que se forja el carácter de cada pieza convirtiéndola en ese objeto que ha de acompañar a futuras generaciones”'];
	} else if (language === 'it') {
		sloganText = '“Crescendo la qualità dello spazio attraverso il design d’oggetti”';
		text = ['Ci sono oggetti che nascono per essere ereditati...',
			'Siamo consapevoli che l\'attaccamento verso di loro esiste, ci circondano e senza rendercene conto con il passare del tempo una parte di noi inevitabilmente vi aderisce.',
			'Da qui nasce l\'impegno di creare oggetti con personalità propria che riescano a comunicare la loro essenza attraverso la loro forma e materialità, che cerca di rompere con la rigidità quotidiana a partire dal disegno a mano libera, lasciando poi che siano le mani di artigiani a mettere il tocco finale per dare a ciascuno dei nostri pezzi il privilegio di trascendere e diventare ereditabili.',
			'"Il design è l\'arte e la bellezza con cui si forgia il carattere di ogni pezzo trasformandolo in quell\'oggetto che deve accompagnare le generazioni future"'];
	}

	React.useEffect(() => {
		setTimeout(() => {
			setStep(1);
		}, 6000);
	}, []);

	const transitions = useTransition(step, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.slow,
		key: (st) => st,
	});
	const page = transitions((style, item, key) => (
		<animated.div
			key={key}
			style={{
				color: 'white',
				fontSize: 18,
				textAlign: 'center',
				maxHeight: 300,
				overflow: 'auto',
				// height: 'auto',
				width: '90%',
				transform: 'translate(50%, -40%)',
				top: '50%',
				right: '50%',
				padding: 20,
				position: 'absolute',
				// backgroundColor: 'white',
				...style,
			}}
		>
			{item === 0 ? (
				<div style={{ textAlign: 'center !important' }}>
					{/* {sloganText} */}
					<img src={nosotros} style={{ width: '70%' }} />
				</div>
			) : item === 1 ? (
				<div style={{ textAlign: 'justify' }}>
					{text.map((e) => (
						<>
							{e}
							<br />
							<br />
						</>
					))}
				</div>

			) : (<></>)}
		</animated.div>
	));

	return (
		<Grid>
			<Row>
				<Col xs={12}>
					{page}

				</Col>
			</Row>
		</Grid>
	);
});
