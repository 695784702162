import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import LoadingLogo from './LoadingLogo';

const useStyles = makeStyles({
	root: {
		position: 'absolute',
		width: '100%',
		height: '100%',
	},
	light: {
		backgroundColor: '#FFFFFF',
		color: '#000000',
	},
	dark: {
		backgroundColor: '#000000',
		color: '#FFFFFF',
	},
	innerDiv: {
		position: 'absolute',
		transform: 'translate(50%, -50%)',
		top: '50%',
		right: '50%',
		fontSize: 25,
	},
});

export default ({
	darkTheme, open,
}) => {
	const classes = useStyles();

	return (
		<div
			className={clsx(classes.root, {
				[classes.dark]: darkTheme,
				[classes.light]: !darkTheme,
			})}
		>
			<div
				className={classes.innerDiv}
			>
				<LoadingLogo open={open} darkTheme={darkTheme} size={120} />
			</div>
		</div>
	);
};
