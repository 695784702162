import React from 'react';
import {
	useSpring, animated, config,
} from 'react-spring';
import { Link } from 'react-router-dom';
import tulum from '../../assets/img/tulum.png';
import oliva from '../../assets/img/oliva.png';
import greece from '../../assets/img/greeceWhite.png';

export default React.memo(({
	isPageOpen, isMenuOpen, isLanguageOpen, icon, language,
}) => {
	let backgroundColor = 'rgba(0,0,0,0.35)';
	let left = '0%';
	let transform = 'translate(0%,0)';
	let opacity = 1;
	let opacity1 = 1;
	if (isPageOpen) {
		backgroundColor = 'rgba(0,0,0,0)';
		left = '50%';
		transform = 'translate(-60%,0)';
		opacity = 0;
	}
	if (isLanguageOpen) {
		opacity1 = 0;
	}

	const spring = useSpring({
		to: {
			backgroundColor,
			left,
			transform,
			opacity,
			opacity1,
		},
		config: config.slow,
	});

	return (
		<animated.div style={{
			position: 'fixed',
			bottom: 0,
			padding: 12,
			width: '100%',
			backgroundColor: spring.backgroundColor,
			opacity: spring.opacity1,
			display: isLanguageOpen ? 'none' : 'block',
		}}
		>
			<div style={{ height: 40, position: 'relative' }}>
				<animated.div style={{
					position: 'absolute',
					left: spring.left,
					transform: spring.transform,
					fontFamily: 'AvenirRegular',
				}}
				>
					<span style={{
						color: 'white', fontSize: 12, lineHeight: 3,
					}}
					>
Andre Restelli Design
					</span>
				</animated.div>

			</div>
			<animated.div
				style={{
					position: 'absolute',
					right: '30px',
					transform: 'translate(0,-50%)',
					top: '50%',
					cursor: 'pointer',
					display: isPageOpen ? 'none' : 'block',
					// marginTop: 4,
					opacity: spring.opacity,
				}}
			>
				<Link to={`${language}/${icon === 1 ? 'tulum' : icon === 2 ? 'oliva' : icon === 3 ? 'greece' : ''}`}>
					<img src={icon === 1 ? tulum : icon === 2 ? oliva : icon === 3 ? greece : ''} alt="" srcSet="" width={icon === 3 ? 75 : 75} />
				</Link>
			</animated.div>

		</animated.div>

	);
});
