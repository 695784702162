/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import { useHistory } from 'react-router';
import Proyect from './Proyect';
import img5 from '../../assets/img/finallow/img1-min-min-thumb.jpg';
import img6 from '../../assets/img/finallow/img11-min-min-thumb.jpg';
import img7 from '../../assets/img/finallow/Parachute-min-min-thumb.jpg';
import clip from '../../assets/img/finallow/clip-thumb.jpeg';
import nalgon from '../../assets/img/finallow/Nalgon-min-min-thumb.jpg';
import cava from '../../assets/img/cava/Cava1.jpg'
import stone from '../../assets/img/sala-piedra/Sala1.jpg'

export default React.memo(({ language }) => {
	const a = 1;

	let fotosText = 'Greece';
	if (language === 'es') {
		fotosText = 'Grecia';
	} else if (language === 'it') {
		fotosText = 'Grecia';
	}

	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
				}}
			>
				<Row>
					<Col
						sm={4}
						lg={4}
						md={4}
						lgOffset={4}
						smOffset={4}
						mdOffset={4}
					>
						<Row style={{ }}>
							<Col xs={6}>
								<Proyect img={img5} text="TULUM" language={language} name="tulum" />
							</Col>
							<Col xs={6}>
								<Proyect img={img6} text="OLIVA" language={language} name="oliva" />
							</Col>
							<Col xs={6} style={{ marginTop: 15 }}>
								<Proyect img={clip} text="CLIP" language={language} name="clip" />
							</Col>
							<Col xs={6} style={{ marginTop: 15 }}>
								<Proyect
									img={nalgon}
									text={fotosText.toUpperCase()}
									language={language}
									name={fotosText.toLowerCase()}
								/>
							</Col>
							<Col xs={6} style={{ marginTop: 15 }}>
								<Proyect img={cava} text="CAVA" language={language} name="cava" />
							</Col>
							<Col xs={6} style={{ marginTop: 15 }}>
								<Proyect img={stone} text="STONE" language={language} name="stone" />
							</Col>
						</Row>

					</Col>
				</Row>
			</Grid>
		</>
	);
});
