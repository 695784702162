import React from 'react';
import {
	animated, config, useSpring, useTransition,
} from 'react-spring';

import ContactPageDesktop from './ContactPage';

import NosotrosPage from './NosotrosPage';
import ProjectPage from './ProjectPage';
import ContactPage from './ContactPage';
import ChairPage from './ChairPage';
import ChairPageTulum from './ChairPageTulum';
import ChairPageClip from './ChairPageClip';
import StonePage from './StonePage'
import CavaPage from './CavaPage';
import FotoPage from './FotoPage';

export default React.memo(({
	isPageOpen, isMenuOpen, isLanguageOpen, openedPage,
	language,
}) => {
	const [innerPageOpen, setInnerPageOpen] = React.useState(false);
	const [innerPageOpen2, setInnerPageOpen2] = React.useState(false);
	React.useEffect(() => {
		if (isPageOpen) {
			setTimeout(() => {
				setInnerPageOpen(true);
			}, 2300);
		} else {
			setInnerPageOpen(false);
		}
	}, [isPageOpen]);

	React.useEffect(() => {
		setInnerPageOpen2(false);
		// if (isPageOpen) {
		setTimeout(() => {
			setInnerPageOpen2(true);
		}, 2000);
		// } else {
		// 	setInnerPageOpen(false);
		// }
	}, [openedPage]);

	const props = useSpring({ opacity: innerPageOpen2 ? 1 : 0 });

	const transitions = useTransition(openedPage, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.slow,
		// config: {
		// 	duration: 4000, // duration for the whole animation form start to end
		// },
		key: (img) => img,
	});

	const page = transitions((style, item, key) => {
		if (item === 1) {
			return (
				<animated.div key={key} style={{ ...style }}>
					<ContactPageDesktop language={language} />
				</animated.div>
			);
		}
		if (item === 2) {
			return (
				<animated.div key={key} style={{ ...style }}>
					<NosotrosPage language={language} />
				</animated.div>
			);
		}
		if (item === 3) {
			return (
				<animated.div key={key} style={{ ...style }}>
					<ProjectPage language={language} />
				</animated.div>
			);
		}
		if (item === 4) {
			return (
				<animated.div style={{ ...props }}>
					<animated.div key={key} style={{ ...style }}>
						<ChairPage language={language} />
					</animated.div>
				</animated.div>
			);
		}
		if (item === 5) {
			return (
				<animated.div style={{ ...props }}>
					<animated.div key={key} style={{ ...style }}>
						<ChairPageTulum language={language} />
					</animated.div>
				</animated.div>
			);
		}
		if (item === 6) {
			return (
				<animated.div style={{ ...props }}>
					<animated.div key={key} style={{ ...style }}>
						<FotoPage language={language} />
					</animated.div>
				</animated.div>
			);
		}
		if (item === 7) {
			return (
				<animated.div style={{ ...props }}>
					<animated.div key={key} style={{ ...style }}>
						<ChairPageClip language={language} />
					</animated.div>
				</animated.div>
			);
		}
		if (item === 8) {
			return (
				<animated.div style={{ ...props }}>
					<animated.div key={key} style={{ ...style }}>
						<CavaPage language={language} />
					</animated.div>
				</animated.div>
			);
		}
		if (item === 9) {
			return (
				<animated.div style={{ ...props }}>
					<animated.div key={key} style={{ ...style }}>
						<StonePage language={language} />
					</animated.div>
				</animated.div>
			);
		}

		return <></>;
	});

	const spring = useSpring({
		to: { opacity: innerPageOpen ? 1 : 0 },
		config: config.slow,
	});

	return (
		<>
			{innerPageOpen ? (
				<animated.div style={{ opacity: spring.opacity }}>
					{page}
				</animated.div>
			) : (<></>)}
		</>

	);
});
