import React from 'react';

export default React.memo(({
	text, value, onValueChange, error,
}) => (
	<div style={{ marginBottom: 15 }}>
		{error
			? (
				<span style={{
					fontWeight: 'bold', color: 'red', fontSize: 14, margin: 10,
				}}
				>
					{text}
				</span>
			)
			: 			<span style={{ color: 'white', fontSize: 14, margin: 10 }}>{text}</span>}
		<textarea
			type="text"
			style={{
				width: '100%',
				margin: 0,
				height: 200,
				border: 'none',
				paddingTop: 15,
				paddingLeft: 17,
				fontSize: 15,
				marginTop: 10,
				backgroundColor: 'rgba(255,255,255,0.8)',
			}}
			value={value}
			onChange={(e) => {
				onValueChange(e.target.value);
			}}
		/>
	</div>
));
