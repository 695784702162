/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import SimpleReactLightbox, {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';
import Proyect from './Proyect';
// import img6 from '../../assets/img/finallow/img1-min-min-t.jpg';
import img6 from '../../assets/img/finallow/Nalgon-min-min-thumb.jpg';
import download from '../../assets/img/whiteDownload.jpg';
import shop from '../../assets/img/shop.png';
import pdf from '../../assets/pdf/grecia.pdf';

import img2 from '../../assets/img/finallow/Icaros-min-min.jpg';
import img7 from '../../assets/img/finallow/Parachute-min-min.jpg';
import img10 from '../../assets/img/finallow/fish-min-min.jpg';
import img13 from '../../assets/img/finallow/Nalgon-min-min.jpg';
import backarrow from '../../assets/img/backarrow.png';

const images = [
	{
		src: img2,
	},
	{
		src: img7,
	},
	{
		src: img10,
	},
	{
		src: img13,
	},
];
export default React.memo(({ language }) => {
	const a = 1;
	const { openLightbox } = useLightbox();

	let fotosText = 'Photography';
	if (language === 'es') {
		fotosText = 'Fotografía';
	} else if (language === 'it') {
		fotosText = 'Fotografia';
	}

	let text = '"My interest in exploring the origins of Architecture and Western Culture took me to Greece. Place in which this photographic project is developed, with the intention of transmitting the rhythm, light, texture, colour and tradition of this culture that represents a great inspiration for all architect”...';
	if (language === 'es') {
		fotosText = 'Fotografía';
	 	text = '“Mi interés por explorar los orígenes de la Arquitectura y la Cultura Occidental me llevó a Grecia. Escenario en el cual se desarrolla este proyecto fotográfico, con la intención de transmitir el ritmo, la luz, la textura, el color y la tradición de esta cultura que representa una gran inspiración para todo arquitecto”…';
	} else if (language === 'it') {
		text = '"Il mio interesse nell\'esplorare le origini dell\'architettura e della cultura occidentale mi ha portato in Grecia. Scenario in cui si sviluppa questo progetto fotografico, con l\'intenzione di trasmettere il ritmo, la luce, la consistenza, il colore e la tradizione di questa cultura che rappresenta una grande ispirazione per ogni architetto"...';
		fotosText = 'Fotografia';
	}

	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					// maxHeight: 500,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
					// backgroundColor: 'blue',
				}}
			>
				<SRLWrapper
					images={[images]}
					options={{
						buttons: {
							showAutoplayButton: false,
							showCloseButton: true,
							showDownloadButton: false,
							showFullscreenButton: false,
							showNextButton: true,
							showPrevButton: true,
							showThumbnailsButton: false,
						},
						settings: {

							overlayColor: 'rgba(0, 0, 0,0.8)',
						},
					}}
				>
					{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
				</SRLWrapper>
				<Row>
					<Col
						xs={12}
						// lg={6}
						// md={6}
						// lgOffset={3}
						// smOffset={3}
						// mdOffset={3}
					>
						<Row style={{ backgroundColor: '' }}>
							<div
								style={{
									width: '100%',
									backgroundImage: `url(${img6})`,
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									// float: 'left',
									height: '200px',

								}}
								onClick={() => {
									openLightbox(0);
								}}
							>

								{/* <img src={img5} width="100%" alt="" srcSet="" /> */}
							</div>
						</Row>
						<Row>
							{/* <div style={{ height: '100%', width: '4%' }} /> */}
							<div style={{
								width: '100%',
								// backgroundColor: 'red',
								// backgroundColor: 'green',
								// padding: 20,
								// float: 'right',
								// height: '300px',

								position: 'relative',
							}}
							>
								<p style={{ fontSize: 16, textAlign: 'justify' }}>
									{text}
									{/* Elaborada con madera de nogal y piel de vacuno de la mano de artesanos Mexicanos, la silla Tulum refleja a través de su forma, su origen. Un diseño concebido mediante el trazo a mano alzada, que invita a dos líneas a fluir hasta converger en su trayecto y formar el boceto de una sola pieza. */}
								</p>

								<div style={{
									bottom: 0,
									right: '50%',
									width: '100%',
								}}
								>
									<button
										style={{
											width: '100%',
											border: 'none',
											cursor: 'pointer',
											background: 'none'
										}}
										onClick={() => {
											window.open(pdf);
										}}
									>
										<img
											src={download}
											alt=""
											srcSet=""
											width="25px"
											style={{ margin: 5 }}
										/>
									</button>
								</div>
							</div>
							{/* <Col xs={7}>

								<img src={img5} width="100%" alt="" srcSet="" />
							</Col> */}
							{/* <Col xs={5}>
								<p style={{ fontSize: 15, textAlign: 'justify' }}>
									Hay objetos que nacen para heredarse…
									<br />
									<br />

									Somos conscientes de que el apego hacia ellos existe, nos rodean y sin darnos cuenta con el paso del tiempo una parte nuestra inevitablemente se adhiere a ellos.

									<br />
									<br />
									De ahí surge el compromiso de crear objetos con personalidad propia que logren comunicar su esencia a través de su forma y materialidad, la cual busca
								</p>
								<div style={{

								}}
								>

									<button style={{
										width: '70%',
										border: 'none',
										backgroundColor: 'rgba(255,255,255,0.8)',
									}}
									>
										<img
											src="https://static.thenounproject.com/png/29074-200.png"
											alt=""
											srcSet=""
											width="25px"
											style={{ margin: 5 }}
										/>
									</button>
									<button style={{
										width: '30%',
										border: 'none',
									}}
									>
										<img
											src="https://banner2.cleanpng.com/20171217/991/shopping-cart-png-5a364b44f3f540.1430760515135076529993.jpg"
											height="25px"
											alt=""
											srcSet=""
											style={{ margin: 5 }}
										/>
									</button>
								</div>

							</Col> */}

						</Row>

					</Col>
				</Row>
			</Grid>
		</>
	);
});
