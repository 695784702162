/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';

import cava1 from '../../assets/img/cava/Cava1.jpg';
import cava2 from '../../assets/img/cava/Cava2.jpg';
import cava3 from '../../assets/img/cava/Cava3.jpg';
import cava4 from '../../assets/img/cava/Cava4.jpg';

const images = [
	{
		src: cava1,
	},
	{
		src: cava2,
	},
	{
		src: cava3,
	},
	{
		src: cava4,
	},

];

export default React.memo(({ language }) => {
	const { openLightbox } = useLightbox();

	let text = 'Located among the forests of Jalisco Mexico, "La Cava" represents the intervention of a cave in a country house to create the wine room.';
	if (language === 'es') {
		text = 'Situada entre los bosques de Jalisco México, "La Cava" representa la intervención del subsuelo en una casa de campo para crear el cuarto del vino.';
	} else if (language === 'it') {
		text = 'Situata tra i boschi di Jalisco Mexico, "La Cava" rappresenta l\'intervento del sottosuolo in una casa di campagna per creare la stanza del vino.';
	}

	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					// maxHeight: 500,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
					// backgroundColor: 'blue',
				}}
			>
				<SRLWrapper
					images={[images]}
					options={{
						buttons: {
							showAutoplayButton: false,
							showCloseButton: true,
							showDownloadButton: false,
							showFullscreenButton: false,
							showNextButton: true,
							showPrevButton: true,
							showThumbnailsButton: false,
						},
						settings: {

							overlayColor: 'rgba(0, 0, 0,0.8)',
						},
					}}
				>
					{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
				</SRLWrapper>
				<Row>
					<Col
						xs={12}
					>
						<Row style={{ backgroundColor: '' }}>
							<div
								style={{
									width: '100%',
									backgroundImage: `url(${cava4})`,
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									// float: 'left',
									height: '200px',

								}}
								onClick={() => {
									openLightbox(0);
								}}
							/>
						</Row>
						<Row>
							<div style={{
								width: '100%',
								position: 'relative',
							}}
							>
								<p style={{ fontSize: 16, textAlign: 'justify' }}>
									{text}
								</p>
							</div>
						</Row>
					</Col>
				</Row>
			</Grid>
		</>
	);
});
