import React from 'react';
import { useSpring, animated } from 'react-spring';

const Logo = ({
	openningPercent, size, opacity,
	darkTheme,
	originalSize,
}) => {
	const x = 370;
	const y = 250;
	const outerSize = 1000;
	const innerSize = 1000 * openningPercent;
	return (
		<animated.svg
			viewBox="0 0 955 3000"
			width={size}
			style={{ opacity, marginLeft: originalSize - size, marginTop: originalSize - size }}

		>
			<path
				fill={darkTheme ? '#FFFFFF' : '#000000'}
				d="M313,0c1.17,2,2.28,4,3.53,6q103.19,159.66,206.3,319.35c2.24,3.48,4.49,4.93,8.72,4.86,16.5-.28,33,.38,49.49-.21,56.34-2,100-26,126.84-76.47,24-45.24,27.86-92.32,7.22-140-14.32-33.14-39.68-55.84-71.24-72.1a210.14,210.14,0,0,0-62.79-20.73,62.27,62.27,0,0,0-10-.58q-112,0-224,.07c-3.95,0-6.42-1.1-8.49-4.44C335.32,10.31,331.57,5.21,328,0H596c13.4,4.38,27.16,7.91,40.14,13.31C671.51,28,701.41,50,722.29,82.73c22.12,34.71,28.65,73,25,113.43a183,183,0,0,1-17.58,63.16c-23.25,48.34-62.28,76.22-114,87.7-13,2.88-26.06,3.13-39.22,3.09-12.13,0-24.26,0-37.11,0,1,1.83,1.61,3.12,2.38,4.3Q747.14,672.19,952.56,990a35.28,35.28,0,0,0,2.44,3c-.67.67-1.32,1.9-2,1.91-7,.14-14,.09-21,.09q-38.44-59-76.89-118Q685.47,616,516,355c-2.35-3.62-4.71-5-9.13-5q-93.74.25-187.47.11h-6.54V664h-20V350.1H6.5c-2.17,0-4.33-.07-6.5-.1V330H292.62v-7.1q0-158,0-315.95c0-2.32.24-4.63.37-6.95ZM499.42,329.79,313.82,44.28l-.87.53v285Z"
			/>
			<animated.path
				fill={darkTheme ? '#000000' : '#FFFFFF'}
				d={`M${x} ${y}m-${outerSize},0a${outerSize},${outerSize},0 1,0 ${outerSize * 2},0a ${outerSize},${outerSize} 0 1,0 ${-outerSize * 2},0zM${x} ${y}m-${innerSize},0a${innerSize},${innerSize},0 0,1 ${innerSize * 2},0a ${innerSize},${innerSize} 0 0,1 ${-innerSize * 2},0z`}
			/>
		</animated.svg>
	);
};

export default ({
	open,
	darkTheme,
	size,
}) => {
	const AnimatedLogo = animated(Logo);
	const growth = 0.7;

	const { openningPercent, logoSize } = useSpring({
		from: {
			openningPercent: 0,
			logoSize: size * growth,
		},
		to: {
			openningPercent: open ? 1 : 0,
			logoSize: open ? size : size * growth,
		},
		config: {
			tension: 150,
			friction: 100,
		},
	});

	const { opacity } = useSpring({
		from: { opacity: 1 },
		to: { opacity: open ? 1 : 1 },
		config: {
			tension: 55,
		},
	});
	return (

		<div
			style={{
				minWidth: 0,
				minHeight: 0,
				width: size + 5,
				height: size,
			}}
		>
			<AnimatedLogo
				openningPercent={openningPercent}
				size={logoSize}
				opacity={opacity}
				darkTheme={darkTheme}
				originalSize={size}
			/>

		</div>
	);
};
