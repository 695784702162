/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';

import sala1 from '../../assets/img/sala-piedra/Sala1.jpg';
import sala2 from '../../assets/img/sala-piedra/Sala2.jpg';
import sala3 from '../../assets/img/sala-piedra/Sala3.jpg';
import sala4 from '../../assets/img/sala-piedra/Sala4.jpg';
import backarrow from '../../assets/img/backarrow.png';

const images = [
	{
		src: sala1,
	},
	{
		src: sala2,
	},
	{
		src: sala3,
	},
	{
		src: sala4,
	},
];

export default React.memo(({ language }) => {
	const { openLightbox } = useLightbox();
	const history = useHistory();

	let text = 'Four stone monoliths are installed to generate the furniture of a living room.';
	if (language === 'es') {
		text = 'Cuatro monolitos de piedra se instalan para generar el mobiliario de una sala.';
	} else if (language === 'it') {
		text = 'Quattro monoliti in pietra sono installati per generare i mobili di una stanza.';
	}

	return (
		<>
			<>
				<Grid
					style={{
						color: 'white',
						fontSize: 20,
						// maxHeight: 500,
						width: '100%',
						overflow: 'auto',
						transform: 'translate(50%, -50%)',
						top: '50%',
						right: '50%',
						padding: 20,
						position: 'absolute',
					// backgroundColor: 'blue',
					}}
				>
					<SRLWrapper
						images={[images]}
						options={{
							buttons: {
								showAutoplayButton: false,
								showCloseButton: true,
								showDownloadButton: false,
								showFullscreenButton: false,
								showNextButton: true,
								showPrevButton: true,
								showThumbnailsButton: false,
							},
							settings: {

								overlayColor: 'rgba(0, 0, 0,0.9)',
							},
						}}
					>
						{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
					</SRLWrapper>
					<Row>
						<Col
							sm={6}
							lg={6}
							md={6}
							lgOffset={3}
							smOffset={3}
							mdOffset={3}
						>
							<Row style={{ backgroundColor: '', minHeight: 350 }}>
								<div
									style={{
										width: '55%',

										backgroundImage: `url(${sala1})`,
										backgroundPosition: 'center center',
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
										float: 'left',
										cursor: 'pointer',

									}}
									onClick={() => {
										openLightbox(0);
									}}
								/>
								<div style={{ height: '100%', width: '4%' }} />
								<div style={{
									width: '41%',
									float: 'right',
									position: 'relative',
								}}
								>
									<p style={{ fontSize: 16, textAlign: 'justify' }}>
										<br />
										<br />
										{text}
									</p>
								</div>
							</Row>
							<div style={{
							 position: 'relative', minHeight: 40, marginTop: 100,
							}}
							>
								<img
									onClick={() => {
										history.goBack();
									}}
									src={backarrow}
									style={{
										height: 35,
										position: 'absolute',
										top: 0,
										left: '50%',
										cursor: 'pointer',

									}}
								/>
							</div>

						</Col>
					</Row>
				</Grid>
			</>
		</>
	);
});
