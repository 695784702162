/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import Proyect from './Proyect';
import img5 from '../../assets/img/finallow/img1-min-min-thumb.jpg';
import img6 from '../../assets/img/finallow/img11-min-min-thumb.jpg';
import img7 from '../../assets/img/finallow/Parachute-min-min-thumb.jpg';
import img8 from '../../assets/img/finallow/clip-thumb.jpeg';
import cava from '../../assets/img/cava/Cava1.jpg'
import stone from '../../assets/img/sala-piedra/Sala1.jpg'


export default React.memo(({ language }) => {
	const a = 1;

	let fotosText = 'GREECE';
	if (language === 'es') {
		fotosText = 'GRECIA';
	} else if (language === 'it') {
		fotosText = 'GRECIA';
	}

	return (
		<>

			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					maxHeight: 500,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
				}}
			>
				<Row>
					<Col
						sm={6}
						lg={6}
						md={6}
						lgOffset={3}
						smOffset={3}
						mdOffset={3}
					>

						<Row style={{ }}>
							<Col xs={12} style={{ marginBottom: 15 }}>
								<Proyect img={img5} text="TULUM" language={language} name="tulum" />
							</Col>
							<Col xs={12} style={{ marginBottom: 15 }}>
								<Proyect img={img6} text="OLIVA" language={language} name="oliva" />
							</Col>
							<Col xs={12} style={{ marginBottom: 15 }}>
								<Proyect img={img8} text="CLIP" language={language} name="clip" />
							</Col>
							<Col xs={12} style={{ marginBottom: 15 }}>
								<Proyect
									img={img7}
									text={fotosText}
									language={language}
									name={fotosText.toLowerCase()}
								/>
							</Col>
							<Col xs={12} style={{ marginBottom: 15 }}>
								<Proyect img={cava} text="CAVA" language={language} name="cava" />
							</Col>
							<Col xs={12} style={{ marginBottom: 15 }}>
								<Proyect img={stone} text="STONE" language={language} name="stone" />
							</Col>
						</Row>

					</Col>
				</Row>
			</Grid>

		</>
	);
});
