import React, { useState } from 'react';
import 'normalize.css';

import { Preload } from 'react-preload';

import { isBrowser, isMobile } from 'react-device-detect';

import { useLocation } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import HomeDesktop from './components/Desktop/HomeDesktop';
import HomeMobile from './components/Mobile/HomeDesktop';

import mo1 from './assets/img/movil/mo1-min.png';
import mo2 from './assets/img/movil/mo2-min.png';
import mo3 from './assets/img/movil/mo3-min.png';
import mo4 from './assets/img/movil/mo4-min.png';

import img1 from './assets/img/finallow/img9-min-min.png';
import img2 from './assets/img/finallow/Icaros-min-min.jpg';
import img3 from './assets/img/finallow/img10-min-min.png';
import img4 from './assets/img/finallow/img7-min-min.png';
import img5 from './assets/img/finallow/img1-min-min.jpg';
import img6 from './assets/img/finallow/img11-min-min.jpg';
import img7 from './assets/img/finallow/Parachute-min-min.jpg';
import img8 from './assets/img/finallow/img3-min-min.jpg';
import img9 from './assets/img/finallow/img2-min-min.jpeg';
import img10 from './assets/img/finallow/fish-min-min.jpg';
import img11 from './assets/img/finallow/img4-min-min.jpg';
import img12 from './assets/img/finallow/img5-min-min.jpg';
import img13 from './assets/img/finallow/Nalgon-min-min.jpg';
import img14 from './assets/img/finallow/img6-min-min.png';
import img15 from './assets/img/finallow/img8-min-min.png';

import img5Thumb from './assets/img/finallow/img1-min-min-thumb.jpg';
import img6Thumb from './assets/img/finallow/img11-min-min-thumb.jpg';
import img5Thumbt from './assets/img/finallow/img1-min-min-t.jpg';
import img6Thumbt from './assets/img/finallow/img11-min-min-t.jpg';
import img7Thumb from './assets/img/finallow/Parachute-min-min-thumb.jpg';
import img8Thumb from './assets/img/finallow/clip-thumb.jpeg';
import img9Thumb from './assets/img/finallow/Nalgon-min-min-thumb.jpg';
import nosotors from './assets/img/nosotros.png';

import LoadingScreen from './components/LoadingScreen';

const images = isBrowser ? [

	img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15,
	img5Thumb,
	img6Thumb,
	img5Thumbt,
	img6Thumbt,
	img7Thumb,
	img8Thumb,
	img9Thumb,
	nosotors,

] : [

	mo1, mo2, mo3, mo4,
];

function App() {
	const [loadingScreen, setLoadingScreen] = useState(true);
	const [open, setOpen] = React.useState(true);

	const location = useLocation();
	const { pathname } = location;

	let language = pathname.split('/')[1];
	if (language === '') {
		language = null;
	} else if (language === 'es') {
		language = 'es';
	} else if (language === 'en') {
		language = 'en';
	} else if (language === 'it') {
		language = 'it';
	} else {
		language = null;
	}

	let openedPage = pathname.split('/')[2];
	if (openedPage === '') {
		openedPage = null;
	} else if (openedPage === 'contact' || openedPage === 'contacto' || openedPage === 'contatto') {
		openedPage = 1;
	} else if (openedPage === 'about' || openedPage === 'nosotros' || openedPage === 'noi') {
		openedPage = 2;
	} else if (openedPage === 'projects' || openedPage === 'proyectos' || openedPage === 'progetti') {
		openedPage = 3;
	} else if (openedPage === 'oliva') {
		openedPage = 4;
	} else if (openedPage === 'tulum') {
		openedPage = 5;
	} else if (openedPage === 'grecia' || openedPage === 'greece') {
		openedPage = 6;
	} else if (openedPage === 'clip') {
		openedPage = 7;
	} else if (openedPage === 'cava') {
		openedPage = 8;
	} else if (openedPage === 'stone') {
		openedPage = 9;
	} else {
		openedPage = null;
	}

	let openProyect = pathname.split('/')[3];
	if (openProyect === '') {
		openProyect = null;
	} else if (openProyect === '1') {
		openProyect = 1;
	} else if (openProyect === '2') {
		openProyect = 2;
	} else {
		openProyect = null;
	}

	React.useEffect(() => {
		setTimeout(() => {
			setOpen(false);
			setTimeout(() => {
				setLoadingScreen(false);
			}, 3000);
		}, 3000);
	}, [language, location.pathname, openedPage, openProyect]);

	if (loadingScreen) {
		return (
			<>
				<LoadingScreen open={open} darkTheme={isMobile} />
				<Preload
					loadingIndicator={<></>}
					images={images}
					autoResolveDelay={10000}
					onError={() => { }}
					onSuccess={() => { }}
					resolveOnError
					mountChildren
				>
					<></>
				</Preload>
			</>
		);
	}

	return (
		<>
			{isBrowser
				? (

					<SimpleReactLightbox>

						<HomeDesktop
							isPageOpen={openedPage !== null}
							isLanguageOpen={language == null}
							language={language}
							openedPage={openedPage}
						/>
					</SimpleReactLightbox>
				)
				: (
					<SimpleReactLightbox>
						<HomeMobile
							isPageOpen={openedPage !== null}
							isLanguageOpen={language == null}
							language={language}
							openedPage={openedPage}
						/>
					</SimpleReactLightbox>
				)}
		</>
	);
}

export default App;
