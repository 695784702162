import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { animated, config, useSpring } from 'react-spring';
import MenuItem from './MenuItem';
 
let animeted;
const useStyles = makeStyles({
	root: {
		position: 'absolute',
		transform: 'translate(0, -50%)',
		top: '50%',
		zIndex: 100,
	},
	menuRoot: {
		position: 'absolute',
		transform: 'translate(0, -50%)',
		zIndex: 200,
		color: 'white',
	},
});

const Logo = ({
	size, width, 
}) => (
	<animated.svg
		id="breathing-button"
		data-name="Layer 1"
		viewBox={`0 0 955 ${size <= 995 ? 995 : size}`}
		width={width}
		fill="white"
		style={{ paddingTop: 9, cursor: 'pointer', animation: size <= 995 ?  'breathing 2s ease-out infinite normal' : 'none'}} //animation:animeted ? 'breathing 2s ease-out infinite normal':'none'
	>
		<animated.path d={`M313,0c1.17,2,2.28,4,3.53,6q103.19,159.66,206.3,319.35c2.24,3.48,4.49,4.93,8.72,4.86,16.5-.28,33,.38,49.49-.21,56.34-2,100-26,126.84-76.47,24-45.24,27.86-92.32,7.22-140-14.32-33.14-39.68-55.84-71.24-72.1a210.14,210.14,0,0,0-62.79-20.73,62.27,62.27,0,0,0-10-.58q-112,0-224,.07c-3.95,0-6.42-1.1-8.49-4.44C335.32,10.31,331.57,5.21,328,0H596c13.4,4.38,27.16,7.91,40.14,13.31C671.51,28,701.41,50,722.29,82.73c22.12,34.71,28.65,73,25,113.43a183,183,0,0,1-17.58,63.16c-23.25,48.34-62.28,76.22-114,87.7-13,2.88-26.06,3.13-39.22,3.09-12.13,0-24.26,0-37.11,0,1,1.83,1.61,3.12,2.38,4.3Q747.14,672.19,952.56,990a35.28,35.28,0,0,0,2.44,3c-.67.67-1.32,1.9-2,1.91-7,.14-14,.09-21,.09q-38.44-59-76.89-118Q685.47,616,516,355c-2.35-3.62-4.71-5-9.13-5q-93.74.25-187.47.11h-6.54V${size}h-20V350.1H6.5c-2.17,0-4.33-.07-6.5-.1V330H292.62v-7.1q0-158,0-315.95c0-2.32.24-4.63.37-6.95ZM499.42,329.79,313.82,44.28l-.87.53v285Z`} />
	</animated.svg>
);

export default React.memo(({
	isPageOpen, isMenuOpen, isLanguageOpen, language,
	onMenuOpen,
	onHomeClick,
	onAboutClick,
	onProjectClick,
	onContactClick,
	onShopClick,
}) => {
	const classes = useStyles();

	let lineHeight = 664;
	let opacity = 0;
	let left = 110;
	let left2 = 150;
	let width = 70;
	let opacity1 = 1;
	if (isMenuOpen) {
		lineHeight = 3000;
		opacity = 1;
	}
	if (isPageOpen) {
		left = 60;
		left2 = 90;
		width = 55;
	}
	if (isLanguageOpen) {
		opacity1 = 0;
	}

	const spring = useSpring({
		to: {
			lineHeight, left, left2, width,
		},
		config: config.slow,
	});

	const spring1 = useSpring({
		to: { opacity, opacity1 },
	});

	let homeText = 'Home';
	let aboutusText = 'About us';
	let projectText = 'Projects';
	let contactText = 'Contact';
	let shopText = 'Shop';

	if (language === 'es') {
		homeText = 'Inicio';
		aboutusText = 'Nosotros';
		projectText = 'Proyectos';
		contactText = 'Contacto';
		shopText = 'Tienda';
	} else if (language === 'it') {
		homeText = 'Inizio';
		aboutusText = 'Noi';
		projectText = 'Progetti';
		contactText = 'Contatto';
		shopText = 'Shop';
	}

	const AnimatedLogo = animated(Logo);
	return 		(
		<>
			<animated.div
				className={classes.root}
				style={{
					left: spring.left,
					 opacity: spring1.opacity1,
					display: isLanguageOpen ? 'none' : 'block',
				}}
				onClick={onMenuOpen}
			>
				<AnimatedLogo
					size={spring.lineHeight}
					width={spring.width}
					style={{ backgroundColor: 'red' }}
				/>
			</animated.div>
			{isMenuOpen ? (

				<animated.div
					style={{
						top: `calc(50% + ${isPageOpen ? 35 : 45}px)`,
						display: isMenuOpen ? 'display' : 'block',
						opacity: spring1.opacity,
						left: spring.left2,
					}}
					className={classes.menuRoot}
				>
					<MenuItem text={aboutusText} onClick={onAboutClick} isPageOpen={isPageOpen} />
					<MenuItem text={projectText} onClick={onProjectClick} isPageOpen={isPageOpen} />
					<MenuItem text={contactText} onClick={onContactClick} isPageOpen={isPageOpen} />
					{/* <MenuItem text={shopText} onClick={onShopClick} isPageOpen={isPageOpen} /> */}
				</animated.div>
			) : (<></>)}
		</>
	);
});
