import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useSpring, animated, config as reactConfig } from 'react-spring';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
	root: {
		position: 'absolute',
		width: '100%',
		height: '100%',
	},
	light: {
		backgroundColor: '#FFFFFF',
		color: '#000000',
	},
	dark: {
		backgroundColor: '#000000',
		color: '#FFFFFF',
	},
	innerDiv: {
		position: 'absolute',
		transform: 'translate(50%, -50%)',
		top: '50%',
		right: '50%',
		fontSize: 25,
	},
	span: {
		cursor: 'pointer',
	},
});

export default React.memo(({
	isPageOpen, isMenuOpen, isLanguageOpen,
}) => {
	let opacity = 0;
	if (isLanguageOpen) {
		opacity = 1;
	}
	const spring = useSpring({
		to: { opacity },
		// config: reactConfig.slow,
	});

	return (

		<animated.div style={{
			position: 'absolute',
			transform: 'translate(50%, -50%)',
			top: '50%',
			right: '50%',
			zIndex: 5,
			color: 'white',
			fontSize: 20,
			opacity: spring.opacity,
			display: !isLanguageOpen ? 'none' : 'block',
		}}
		>

			<Link to="es">
				<span style={{ color: 'white' }}>
						ESP&nbsp;&nbsp;
				</span>
			</Link>
			<span>/</span>
			<Link to="en">
				<span>
						&nbsp;&nbsp;ENG&nbsp;&nbsp;
				</span>
			</Link>
			<span>/</span>
			<Link to="it">
				<span>
						&nbsp;&nbsp;ITA
				</span>
			</Link>
		</animated.div>

	);
});
