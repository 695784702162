/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';
import {
	SRLWrapper,
	useLightbox,
} from 'simple-react-lightbox';
import download from '../../assets/img/whiteDownload.jpg';
import pdf from '../../assets/pdf/oliva.pdf';

import img6 from '../../assets/img/finallow/img11-min-min.jpg';
import img11 from '../../assets/img/finallow/img4-min-min.jpg';
import img12 from '../../assets/img/finallow/img5-min-min.jpg';

const images = [
	{
		src: img6,
	},
	{
		src: img11,
	},
	{
		src: img12,
	},
];
export default React.memo(({ language }) => {
	const { openLightbox } = useLightbox();

	let fotosText = 'Photography';
	if (language === 'es') {
		fotosText = 'Fotografía';
	} else if (language === 'it') {
		fotosText = 'Fotografia';
	}

	let text = 'Elaborada artesanalmente en México, la silla Oliva ostenta un sutil diseño que revive algunos gestos característicos de la silla tradicional mexicana; el tejido en piel de su asiento y la forma en que fluye su estructura le otorgan un balance entre lo tradicional y lo contemporáneo…';
	if (language === 'es') {
		fotosText = 'Fotografía';
	} else if (language === 'it') {
		text = 'Elaborata artigianalmente in Messico, la sedia Oliva vanta un design sottile che rivive alcuni gesti caratteristici della sedia tradizionale messicana; il tessuto in pelle del suo sedile e il modo in cui fluisce la sua struttura gli conferiscono un equilibrio tra il tradizionale e il contemporaneo…';
		fotosText = 'Fotografia';
	}

	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					// maxHeight: 500,
					width: '100%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
					// backgroundColor: 'blue',
				}}
			>
				<SRLWrapper
					images={[images]}
					options={{
						buttons: {
							showAutoplayButton: false,
							showCloseButton: true,
							showDownloadButton: false,
							showFullscreenButton: false,
							showNextButton: true,
							showPrevButton: true,
							showThumbnailsButton: false,
						},
						settings: {

							overlayColor: 'rgba(0, 0, 0,0.8)',
						},
					}}
				>
					{images.map((e) => <img src={e.src} style={{ display: 'none' }} />)}
				</SRLWrapper>
				<Row>
					<Col
						xs={12}
						// lg={6}
						// md={6}
						// lgOffset={3}
						// smOffset={3}
						// mdOffset={3}
					>
						<Row style={{ backgroundColor: '' }}>
							<div
								style={{
									width: '100%',
									backgroundImage: `url(${img6})`,
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									// float: 'left',
									height: '200px',

								}}
								onClick={() => {
									openLightbox(0);
								}}
							>

								{/* <img src={img5} width="100%" alt="" srcSet="" /> */}
							</div>
						</Row>
						<Row>
							{/* <div style={{ height: '100%', width: '4%' }} /> */}
							<div style={{
								width: '100%',
								// backgroundColor: 'red',
								// backgroundColor: 'green',
								// padding: 20,
								// float: 'right',
								// height: '300px',

								position: 'relative',
							}}
							>
								<p style={{ fontSize: 16, textAlign: 'justify' }}>
									{text}
									{/* Elaborada con madera de nogal y piel de vacuno de la mano de artesanos Mexicanos, la silla Tulum refleja a través de su forma, su origen. Un diseño concebido mediante el trazo a mano alzada, que invita a dos líneas a fluir hasta converger en su trayecto y formar el boceto de una sola pieza. */}
								</p>

								<div style={{
									bottom: 0,
									right: '50%',
									width: '100%',
								}}
								>
									<button
										style={{
											width: '100%',
											border: 'none',
											cursor: 'pointer',
											background: 'none',
										}}
										onClick={() => {
											window.open(pdf);
										}}
									>
										<img
											src={download}
											alt=""
											srcSet=""
											width="25px"
											style={{ margin: 5 }}
										/>
									</button>
								</div>
							</div>
						</Row>

					</Col>
				</Row>
			</Grid>
		</>
	);
});
