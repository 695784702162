import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import {
	useTransition, animated, config,
} from 'react-spring';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: {
		height: '100vh',
		width: '100vw',
		position: 'absolute',
		zIndex: -100,
		overflow: 'hidden',
	},
	gallery: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '103%',
	},
});

const Gallery = React.memo(({ onChangeImage, imgs }) => {
	const classes = useStyles();
	const [index, setIndex] = useState(0);

	useEffect(() => {
		onChangeImage(imgs[index]);
	}, [imgs, index, onChangeImage]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!document.hidden) {
				setIndex((i) => (i === imgs.length - 1 ? 0 : i + 1));
			}
		}, 6000);
		return () => clearInterval(interval);
	}, [imgs.length]);

	const galleryTransition = useTransition(imgs[index], {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.slow,
		key: (img) => img.id,
	});

	const galleryElements = galleryTransition((style, item, key) => (
		<animated.div
			key={key}
			className={clsx(classes.gallery, 'animate')}
			style={{
				...style,
				background: `url(${item.src}) center center no-repeat`,
				backgroundSize: 'cover',
			}}
		/>
	));

	return (
		<div className={classes.root}>
			{galleryElements}
		</div>
	);
});
export default Gallery;
