import React from 'react';

export default React.memo(({
	text, value, onValueChange, error, full,
}) => (
	<div style={{ paddingLeft: 5, marginBottom: 7 }}>
		{error
			? (
				<span style={{
					fontWeight: 'bold', color: 'red', fontSize: 14,
				}}
				>
					{text}
				</span>
			)
			: 			<span style={{ color: 'white', fontSize: 14 }}>{text}</span>}
		<input

			type="text"
			style={{
				width: full ? '97%' : '93%',
				// margin: 0,
				height: 35,
				border: 'none',
				padding: 6,
				fontSize: 15,
				marginTop: 3,
				backgroundColor: 'rgba(255,255,255,0.8)',
			}}
			value={value}
			onChange={(e) => {
				onValueChange(e.target.value);
			}}
		/>
	</div>
));
