/* eslint-disable react/button-has-type */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import emailjs from 'emailjs-com';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {
	animated, useSpring, config,
} from 'react-spring';
import Input from './Input';
import TextField from './TextField';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Spring } from 'react-spring/renderprops';

function validateEmail(mail) {
	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
		return (true);
	}
	return (false);
}

export default React.memo(({ language }) => {
	const [name, setName] = React.useState('');
	const [surname, setSurName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [sent, setSent] = React.useState(false);
	const [sending, setSending] = React.useState(false);

	const [nameError, setNameError] = React.useState(null);
	const [surnameError, setSurnameError] = React.useState(null);
	const [emailError, setEmailError] = React.useState(null);
	const [messageError, setMessageError] = React.useState(null);

	let nameText = 'Name';
	let surnameText = 'Surname';
	let emailText = 'Email';
	let messageText = 'Message';
	let sendMessage = 'SEND';
	let sendingMessage = 'SENDING';
	let sentMessage = 'SENT';

	if (language === 'es') {
		nameText = 'Nombre';
		surnameText = 'Apellido';
		emailText = 'Correo';
		messageText = 'Mensaje';
		sendMessage = 'ENVIAR';
		sendingMessage = 'ENVIANDO';
		sentMessage = 'ENVIADO';
	} else if (language === 'it') {
		nameText = 'Nome';
		surnameText = 'Cognome';
		emailText = 'Email';
		messageText = 'Messaggio';
		sendMessage = 'INVIA';
		sendingMessage = 'INVIO';
		sentMessage = 'INVIATO';
	}

	const setSurNameCB = React.useCallback((e) => {
		setSurName(e);
	}, []);
	const setNameCB = React.useCallback((e) => {
		setName(e);
	}, []);
	const setEmailCB = React.useCallback((e) => {
		setEmail(e);
	}, []);
	const setMessageCB = React.useCallback((e) => {
		setMessage(e);
	}, []);
	return (
		<>
			<Grid
				style={{
					color: 'white',
					fontSize: 20,
					maxHeight: 600,
					width: '70%',
					overflow: 'auto',
					transform: 'translate(50%, -50%)',
					top: '50%',
					right: '50%',
					padding: 20,
					position: 'absolute',
				}}
			>
				<Row>
					<Col sm={10} lg={10} md={10} lgOffset={1} smOffset={1} mdOffset={1} style={{}}>
						<Row>

							<>
								<Col xs={6} style={{ paddingRight: 30 }}>
									<Input
										value={name}
										onValueChange={setNameCB}
										text={`${nameText} *`}
										error={nameError}
									/>

								</Col>
								<Col xs={6} style={{ paddingLeft: 30 }}>
									<Input
										text={`${surnameText} *`}
										value={surname}
										onValueChange={setSurNameCB}
										error={surnameError}
									/>

								</Col>
							</>

						</Row>
						<Row>
							<Col xs={12}>
								<Input
									text={`${emailText} *`}
									value={email}
									onValueChange={setEmailCB}
									error={emailError}
								/>

							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<TextField
									value={message}
									onValueChange={setMessageCB}
									text={`${messageText} *`}
									error={messageError}
								/>

							</Col>
						</Row>
						<Row>
							<Col xs={12}>

								<animated.button
									style={{
										width: 180,
										height: 50,
										marginTop: 10,
										border: 'none',
										type: 'send',
										backgroundColor: 'rgba(255,255,255,0.8)',
										// background,

									}}
									onClick={() => {
										let valid = true;
										if (name.length < 3) {
											valid = false;
											setNameError(true);
										} else {
											setNameError(false);
										}
										if (surname.length < 3) {
											valid = false;
											setSurnameError(true);
										} else {
											setSurnameError(false);
										}
										if (email.length < 3 || !validateEmail(email)) {
											valid = false;
											setEmailError(true);
										} else {
											setEmailError(false);
										}
										if (message.length < 3) {
											valid = false;
											setMessageError(true);
										} else {
											setMessageError(false);
										}

										if (valid) {
											setSending(true);
											emailjs.send('service_3kom668', 'template_vhqi0z7', {
												name: `${name} ${surname}`,
												email,
												message,
											}, 'user_wy2yeZnmoKjOAbs8cwYr6').then(() => {
												setEmail('');
												setName('');
												setSurName('');
												setMessage('');
												setSending(false);
												setSent(true);
											}).catch(() => {
												setSending(false);
												// alert('error');
											});
										}
									}}
								>
									<span style={{ fontSize: 14 }}>
										{sent ? sentMessage : sending ? sendingMessage : sendMessage}
									</span>

								</animated.button>

							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>
		</>
	);
});
