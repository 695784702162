import React from 'react';

export default ({ text, onClick, isPageOpen }) => (
	<div style={{ marginBottom: !isPageOpen ? 7 : 3 }}>
		<span
			onClick={onClick}
			style={{
				fontSize: !isPageOpen ? 18 : 15,
				cursor: 'pointer',
				padding: 0,
				paddingRight: 20,

			}}
			className="onHover"
		>
			{text}
		</span>
	</div>
);
