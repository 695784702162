import { makeStyles } from '@material-ui/styles';
import React from 'react';
import {
	useSpring, animated, config,
} from 'react-spring';

const vhToPixel = (value) => `${(window.innerHeight * value) / 100}px`;
const vwToPixel = (value) => `${(window.innerWidth * value) / 100}px`;

const useStyles = makeStyles({
	root: {
		height: '100vh',
		width: '100vw',
		position: 'absolute',
		zIndex: 0,
	},
	leftPanel: {
		backgroundColor: 'rgba(0,0,0,0.45)',
		position: 'absolute',
		transform: 'translate(0, -50%)',
		top: '50%',
		right: 0,
	},
	rightPanel: {
		backgroundColor: 'rgba(0,0,0,0.45)',
		position: 'absolute',
		transform: 'translate(0, -50%)',
		top: '50%',
	},
});

export default React.memo(({
	isPageOpen, isMenuOpen, isLanguageOpen,
}) => {
	const classes = useStyles();
	const [dimensions, setDimensions] = React.useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	let width = '300px';
	let height = '125px';

	if (isPageOpen || isLanguageOpen) {
		width = vwToPixel(50);
	}

	if (isPageOpen) {
		height = vhToPixel(100);
	} else if (isMenuOpen) {
		height = '300px';
	}

	const spring = useSpring({
		to: { width, height },
		config: config.slow,
	});

	React.useEffect(() => {
		function handleResize() {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	// useChain(isPageOpen ? [springRef1, springRef2] : [springRef2, springRef1]);

	return (
		<div className={classes.root}>
			<animated.div
				style={spring}
				className={classes.leftPanel}
			/>
			<animated.div
				style={spring}
				className={classes.rightPanel}
			/>
		</div>
	);
});
